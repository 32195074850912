import * as React from "react"
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import flagaPolski from "../images/flagaPolski.svg"
import herbPolski from "../images/herbPolski.svg"
import piaseczno from "../images/piaseczno.svg"
import piaseczynski from "../images/piaseczynski.png"
import nobell from "../images/nobell.jpg"

import oEdumapieGrafika from "../images/oedumapie_grafika.svg"
import oEdumapieTekst from "../images/coznajdziesz_tekst.svg"
import celEdumapyGrafika from "../images/celedumapy_grafika.svg"
import celEdumapyTekst from "../images/celedumapy_tekst.svg"


const OEdumapiePage = () => {
    return (
        <DefaultLayout>
            <SEO title="o edumapie" />
            <article className="prose prose-xl">
                <img className="h-80 mx-auto" src={oEdumapieGrafika} alt="o edumapie"></img>
                <img className="h-24 mx-auto" src={oEdumapieTekst} alt="o edumapie"></img>
                <p>Edumapa to “szkoła poza szkołą” oferująca gotowe scenariusze lekcji dla uczniów wszystkich poziomów edukacyjnych. Na mapie powiatu piaseczyńskiego poprowadziliśmy trasy i zaznaczyliśmy punkty, w których uczniowie zmierzą się z zadaniami.</p>
                <p>Wybierz trasę, wydrukuj scenariusze i zabierz swoich uczniów szlakami edukacyjnymi Góry Kalwarii, Konstancina-Jeziorny, Lesznowoli, Piaseczna, Prażmowa i Tarczyna. Czekają tam na nich wyzwania z geografii, przyrody, biologii, chemii, fizyki, matematyki, plastyki, języka polskiego, języków obcych, historii i WOS</p>

                <img className="h-80 mx-auto" src={celEdumapyGrafika} alt="cel edumapy"></img>
                <img className="h-12 mx-auto" src={celEdumapyTekst} alt="cel edumapy"></img>
                <p>Jesteśmy nauczycielami, edukatorami, społecznikami, którzy chcą zmieniać edukację na lepsze.</p>
                <p>Stworzyliśmy narzędzie, które ułatwia nauczycielom wyjście z nauczaniem poza szkolne mury.
                    Naszym celem jest wprowadzenie młodych ludzi w holistyczne rozumienie świata trasami ciekawych historycznie i przyrodniczo miejsc,
                    wzbogaconych matematycznymi, fizycznymi czy chemicznymi wyzwaniami oraz pobudzenie kreatywności podczas językowych sztuczek czy malowniczych plenerów.
                </p>


                <p>Nie trzeba planować dalekich wycieczek do odległych muzeów. Wystarczy wydrukować scenariusz i ruszyć z klasą zdobywać wiedzę w najbliższej okolicy.</p>
                <p>
                    Sięgnijcie po lokalne zasoby: historyczne, kulturowe czy przyrodnicze.
                    Skorzystajcie z ciekawych miejsc, żeby Wasi uczniowie nauczyli się matematyki i fizyki, a przy okazji poszerzyli słownictwo z języka obcego czy rozwinęli pokłady kreatywności układając obrazy z szyszek.
                    Nie musicie być specjalistami z przedmiotów, których dotyczy scenariusz. Uczniowie poradzą sobie sami.
                </p>
                <p>
                    Nauka w terenie przynosi wiele korzyści uczniom i nauczycielom: sprzyja harmonijnemu rozwojowi fizycznemu i psychicznemu, 
                    zachęca do aktywności w otoczeniu przyrody, rozwija kluczowe kompetencje i dobre relacje z otoczeniem.
                </p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/T5jTW_68cmY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <hr />
                <p>
                    Projekt został zrealizowany przez Fundację Ludzi Otwartej Wyobraźni FLOW
                    <br/>
                    Scenariusze przygotowano w programie Canva.
                    <br/>
                    Autorami scenariuszy są nauczyciele.
                    <br/>
                    Współpraca informatyczna Zdzisław Krajewski
                    <br/>
                    Współpraca graficzna Jarek Kalinowski
                </p>
            </article>
            <div className="mt-3">
                <div className="mt-3">
                    <h4 className="text-2xl">Patroni:</h4>
                    <div className="flex flex-row items-center mt-4">
                        <img src={ piaseczno} alt="logo piaseczno" className="h-32 mr-6"/>
                        <img src={ nobell } alt="logo nobell" className="h-20 mr-6"/>
                        <img src={ piaseczynski } alt="logo piaseczynski" className="h-20 mr-6"/>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={flagaPolski} alt="flaga polski" className="h-20 float-right border border-gray-400"/>
                    <p className="mx-4">
                        Projekt Edu mapa jest finansowany z budżetu państwa. Wartość projektu w poszczególnych latach wynosi: w 2021 r. 95 450,00 zł, w 2022 r. 67 250,00 zł, w 2023 r. 116 880,00 zł.
                    </p>
                    <img src={herbPolski} alt="herb polski" className="h-20 float-right mr-4"/>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default OEdumapiePage